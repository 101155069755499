<template>
  <div
    class="md:rounded-full md:bg-gradient-to-r md:text-white md:text-center md:px-8 md:py-3"
    :class="{
      'from-primary-800 to-light-blue-accent':
        isFreeMember() && !isResponsive(),
      'from-lighter-blue-accent to-lighter-light-blue-accent':
        isSilverMember() && !isResponsive(),
      'from-orange-accent to-lighter-orange-accent':
        isGoldMember() && !isResponsive(),
      'md:from-primary-800 md:to-light-blue-accent':
        isFreeMember() && isResponsive(),
      'md:from-lighter-blue-accent md:to-lighter-light-blue-accent':
        isSilverMember() && isResponsive(),
      'md:from-orange-accent md:to-lighter-orange-accent':
        isGoldMember() && isResponsive()
    }"
  >
    <div v-show="isResponsive()" class="md:hidden block px-2">
      <span
        class="inline-block w-2 h-2 rounded-full"
        :class="{
          'bg-primary-800': isFreeMember(),
          'bg-lighter-blue-accent': isSilverMember(),
          'bg-orange-accent': isGoldMember()
        }"
      ></span>
      <span class="px-2 font-base text-base">{{ getLabel() }}</span>
    </div>
    <span class="md:inline hidden">
      {{ getLabel() }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default () {
        return 'free'
      }
    },
    responsive: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  methods: {
    isFreeMember () {
      return this.type === 'free'
    },
    isSilverMember () {
      return this.type === 'silver'
    },
    isGoldMember () {
      return this.type === 'gold'
    },
    getLabel () {
      if (this.isGoldMember()) {
        return 'Gold Member'
      }
      if (this.isSilverMember()) {
        return 'Silver Member'
      }
      return 'Free Member'
    },
    isResponsive () {
      return this.responsive
    }
  }
}
</script>
