<template>
  <div class="min-h-screen">
    <div
      class="lg:px-40 w-full px-5 py-12 flex flex-row lg:flex-nowrap flex-wrap bg-background-50"
    >
      <div class="lg:w-28 lg:h-28 w-16 h-16 rounded-full">
        <img
          class="object-cover w-full h-full writter-svg"
          :src="require('@/assets/img/user-default-small.webp')"
          alt=""
          v-if="GET_USER.avatar !== undefined"
        />
        <svg
          v-else
          class="w-full h-full"
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          >
          <circle cx="24" cy="24" r="24" fill="#E0E0E0" />
          <mask
            id="mask0"
            mask-type="alpha"
            maskUnits="userSpaceOnUse"
            x="0"
            y="0"
            width="48"
            height="48"
          >
            <circle cx="24" cy="24" r="24" fill="#E0F1F2" />
          </mask>
          <g mask="url(#mask0)">
            <path
              d="M24.375 36.1541C11.7619 36.1541 1.5 46.416 1.5 59.029C1.5 59.578 1.9456 60.0236 2.4946 60.0236H46.2554C46.8044 60.0236 47.25 59.578 47.25 59.029C47.25 46.4159 36.9881 36.1541 24.375 36.1541Z"
              fill="#FAFAFA"
            />
            <path
              d="M24.3746 33.8804C30.4167 33.8804 35.3148 28.9823 35.3148 22.9402C35.3148 16.8981 30.4167 12 24.3746 12C18.3325 12 13.4344 16.8981 13.4344 22.9402C13.4344 28.9823 18.3325 33.8804 24.3746 33.8804Z"
              fill="#FAFAFA"
            />
          </g>
        </svg>
      </div>
      <div class="lg:px-12 px-4 lg:py-4 py-2">
        <ArticleCardLoading
          v-if="!USER_READY"
          body-class="w-full  h-5"
        />
        <ArticleCardLoading
          v-if="!USER_READY"
          body-class="w-full mt-2 h-10"
        />
        <span class="block" v-if="USER_READY">
          Join at {{ moment(GET_USER.created_at).format('DD/MM/YYYY') }}
        </span>
        <span class="font-semibold lg:text-headline-3 text-headline-6">
          {{ GET_USER.fullname }}
        </span>
      </div>
      <div class="flex-auto md:w-auto w-full md:py-8 py-1 relative" v-if="false">
        <UserTypeBadge
          class="md:float-right md:relative md:left-0 md:top-0 px-3 absolute left-16 -top-3"
          :type="getRandomUserType()"
          responsive
        />
      </div>
    </div>
    <div class="py-12">
      <div class="flex flex-row lg:px-40 px-2 py-4 border-b border-gray-200">
        <ul class="flex justify-center items-center">
          <template v-for="(tab, index) in tabs">
            <li
              :key="index"
              class="cursor-pointer px-6 py-2"
              :class="{
                'text-gray-500': selectedTab !== index,
                'text-gray': selectedTab === index
              }"
              @click="selectedTab = index"
            >
              {{ tab }}
            </li>
          </template>
        </ul>
      </div>
      <div
        v-if="selectedTab === 0 && !ARTICLE_READY"
        class="flex flex-row flex-wrap lg:px-40 px-4 mt-2"
      >
        <ArticleCardLoading
          body-class="w-full  h-20"
        />
        <ArticleCardLoading
          body-class="w-full  h-40 mt-5"
        />
      </div>
      <div
        v-if="selectedTab === 1 && !VIEWED_ARTICLES_READY"
        class="flex flex-row flex-wrap lg:px-40 px-4 mt-2"
      >
        <ArticleCardLoading
          body-class="w-full  h-20"
        />
        <ArticleCardLoading
          body-class="w-full  h-40 mt-5"
        />
      </div>
      <div
        v-show="selectedTab === 0 && ARTICLE_READY"
        class="flex flex-row flex-wrap lg:px-40 px-4 mt-2"
      >
      <div v-if="acceptedSavedArticles!==undefined && acceptedSavedArticles.length < 1">Tidak ada artikel yang disimpan saat ini.</div>
        <ArticleCard
          hideBookmark
          class="sm:px-3"
          v-for="item in acceptedSavedArticles"
          :key="'article' + item.article.id"
          :image-src="articleImage(item.article.thumbnail)"
          :slug="item.article.article_title.replace(/ /g,'-')"
          :title="item.article.article_title"
          :category="item.article.category.article_category_name"
          :published-date="moment(item.article.published_at).format('DD/MM/YYYY')"
          :author="item.article.writter"
          bookmarked
          responsive
        />
      </div>
      <div
        v-show="selectedTab === 1 && VIEWED_ARTICLES_READY"
        class="flex flex-row flex-wrap lg:px-40 px-4 mt-2"
      >
        <div v-if="acceptedViewedArticles!==undefined && acceptedViewedArticles.length < 1">Kamu belum membaca articel saat ini.</div>
        <ArticleCard
          class="sm:px-3"
          v-for="item in acceptedViewedArticles"
          :key="'article' + item.article_id"
          :image-src="articleImage(item.article.thumbnail)"
          :slug="item.article.article_title.replace(/ /g,'-')"
          :title="item.article.article_title"
          :category="item.article.category.article_category_name"
          :published-date="moment(item.article.published_at).format('DD/MM/YYYY')"
          :author="item.article.writter"
          bookmarked
          hideBookmark
          responsive
        />
        <!-- <ArticleCard
          class="sm:px-3"
          v-for="index in 6"
          :key="index"
          :image-src="getRandomImage()"
          slug=""
          title="Building your portfolio in Notion: Part 1"
          category="Graphic Design"
          published-date="Dec 23, 2020"
          :author="author"
          responsive
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
import ArticleCard from '@/components/Articles/ArticleCard.vue'
import UserTypeBadge from '@/components/Utilities/UserTypeBadge.vue'
import ArticleCardLoading from '@/components/Articles/ArticleCardLoading.vue'
import { createNamespacedHelpers } from 'vuex'

const user = createNamespacedHelpers('user')
const article = createNamespacedHelpers('article')

export default {
  components: {
    ArticleCard,
    UserTypeBadge,
    ArticleCardLoading
  },
  data () {
    return {
      selectedTab: 0,
      tabs: ['Saved', 'Recently viewed'],
      author: {
        name: 'Rama Krushna',
        avatar: require('@/assets/img/author-avatar-small-1.png')
      },
      userTypes: ['free', 'silver', 'gold'],
      images: [
        require('@/assets/img/article-header-preview-1.jpg'),
        require('@/assets/img/article-header-preview-2.jpg'),
        require('@/assets/img/article-header-preview-3.jpg'),
        require('@/assets/img/article-header-preview-4.jpg'),
        require('@/assets/img/article-header-preview-5.jpg'),
        require('@/assets/img/article-header-preview-6.jpg')
      ],
      fetchOption: {
        limit: 10,
        page: 1
      }
    }
  },
  props: {
    ismember: Boolean
  },
  watch: {
    ismember (val) {
      if (val === false) {
        this.$router.push('/')
      }
    }
  },
  computed: {
    ...user.mapGetters(['GET_USER', 'USER_READY', 'GET_VIEWED_ARTICLES', 'VIEWED_ARTICLES_READY']),
    ...article.mapGetters(['GET_ARTICLES', 'ARTICLE_READY']),
    acceptedViewedArticles () {
      return this.GET_VIEWED_ARTICLES.filter(item => item.article != null)
    },
    acceptedSavedArticles () {
      return this.GET_ARTICLES.filter(item => item.article != null)
    }
  },
  methods: {
    ...user.mapActions(['getProfile', 'getViewedArticles', 'initUserProfile']),
    ...article.mapActions(['getSavedArticles', 'createInit']),
    getRandomImage () {
      return this.images[Math.floor(Math.random() * this.images.length)]
    },
    getRandomUserType () {
      return this.userTypes[Math.floor(Math.random() * this.userTypes.length)]
    }
  },
  created () {
    if (this.ismember === false) {
      this.$router.push('/')
    }
    this.createInit()
    this.initUserProfile()
    this.getProfile()
    this.getViewedArticles()
    this.getSavedArticles({ data: this.fetchOption })
  }
}
</script>
